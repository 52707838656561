import React, { useMemo, useState } from "react";
import { phoneNumber as phoneNumberStyles } from "./FormContact.module.scss";
import cx from "classnames";
import get from "lodash/get";
import {
  CustomSelectMulti,
  CustomSelectSingle,
} from "../CustomSelect/CustomSelect";
import { formError } from "../../styles/forms/forms.module.scss";
import { contactDatePickerContainer } from "../../styles/forms/datepicker.module.scss";
import {
  optionsCity,
  optionsCompanySize,
  optionsLocationInterest,
  optionsNumberOfGuests,
  optionsNumberOfPeople,
  optionsPaymentType,
  optionsPhoneNumberCountry,
} from "./options";
import DropdownDatePicker from "../DropdownDatePicker/DropdownDatePicker";
import { useFormik } from "formik";
import * as Yup from "yup";
import { phoneRegExp } from "../../utils/forms/submitContactForm";
import SubmitButton from "../../SubmitButton";
import i18n from "../../utils/i18n";
import CheckboxChecked from "../../images/design-components/Checkbox_2.svg";
import Checkbox from "../../images/design-components/Checkbox_1.svg";
import submitFormToHubSpot from "../../utils/submitFormToHubSpot";
import {
  areYouLookingForOfficeSpace,
  currentWorkingEnvironment,
  industries,
  iqLocations,
  membershipProductTypes,
  teamSizes,
} from "../../constants/hubSpotFieldValues";

const FormContact = (props) => {
  const [progress, setProgress] = useState(false);
  const [sent, setSent] = useState(false);
  const {
    to = "",
    companyName,
    companySize,
    locationInterest,
    productInterest,
    locationClientInterest,
    productClientInterest,
    moveInDate,
    eventDate,
    clientName,
    clientJobTitle,
    clientIndustry,
    numberOfGuests,
    numberOfPeople,
    city,
    isProductInquiry = false,
    foundOutIQ,
    customEmailFields = [],
    businessAddress,
    messagePlaceholder = i18n.t("Additional information about your inquiry."),
    phoneNumber,
    marketingEmails,
    submitButtonClassName = "",
    paymentType,
    selectedForm,
    fullWidth = false,
    mostRecentSource = "",
  } = props;
  const validationObject = {
    firstName: Yup.string().required(i18n.t("Required")),
    lastName: Yup.string().required(i18n.t("Required")),
    email: Yup.string()
      .email(i18n.t("InvalidEmailAddress"))
      .required(i18n.t("Required")),
    phoneNumber: Yup.string().matches(
      phoneRegExp,
      i18n.t("PhoneNumberIsNotValid"),
    ),
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumberCountry: "CA",
      phoneNumber: "",
      additionalInfo: "",
      companyName: "",
      companySize: "",
      locationInterest: [],
      locationClientInterest: [],
      productInterest: [],
      productClientInterest: [],
      numberOfGuests: "",
      numberOfPeople: "",
      moveInDate: "",
      foundOutIQ: "",
      teamSize: {},
      lookingForSpace: {},
      currentWorkingEnvironment: {},
      eventDate: "",
      clientName: "",
      clientJobTitle: "",
      clientIndustry: "",
      city: [],
      businessAddress: "",
      marketingEmails: false,
      paymentType: {},
      transitNumber: "",
      institutionNumber: "",
      accountNumber: "",
    },
    validationSchema: Yup.object(validationObject),
    onSubmit: (values) => {
      handleSubmit().then(() => null);
    },
  });

  const privateOfficeSelected = useMemo(() => {
    return formik.values.productInterest?.find(
      (p) => p.value === "Private Office",
    );
  }, [formik.values.productInterest]);

  const isEnglish = i18n.t("lang") !== "fr";

  const formId = selectedForm?.formIds?.[isEnglish ? "en" : "fr"];

  const handleSubmit = async () => {
    if (!formik.isValid) return;
    setProgress(true);

    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      additionalInfo,
      marketingEmails,
    } = formik.values;

    const formattedFields = [
      { name: "firstname", value: firstName },
      { name: "lastname", value: lastName },
      { name: "phone", value: phoneNumber },
      { name: "email", value: email || "" },
      { name: "message", value: additionalInfo || "" },
      {
        name: "member_marketing_e_mails",
        value: !!marketingEmails ? "Yes" : "No",
      },
      { name: "most_recent_source", value: mostRecentSource || "" },
    ];

    if (isProductInquiry) {
      // add the additional fields
      formattedFields.push({
        name: "company",
        value: formik?.values?.companyName || "",
      });
      formattedFields.push({
        name: "company_industry",
        value: formik?.values?.clientIndustry,
      });
      formattedFields.push({
        name: "membership_product",
        value: formik?.values?.productInterest?.map((p) => p.value).join(";"),
      });
      formattedFields.push({
        name: "iq_offices_location",
        value: formik?.values?.locationInterest?.map((p) => p.value).join(";"),
      });
      formattedFields.push({
        name: "how_did_you_hear_about_us_",
        value: formik?.values?.foundOutIQ || "",
      });

      if (privateOfficeSelected) {
        formattedFields.push({
          name: "team_size",
          value: formik?.values?.teamSize?.value || "",
        });
        formattedFields.push({
          name: "are_you_looking_for_office_space_",
          value: formik?.values?.lookingForWork?.value || "",
        });
        formattedFields.push({
          name: "current_work_environment",
          value: formik?.values?.currentWorkingEnvironment?.value || "",
        });
      }
    }

    await submitFormToHubSpot(formId, formattedFields)
      .then((res) => null)
      .catch((e) => {})
      .finally(() => {
        setProgress(false);
        setSent(true);
      });

    // if (
    //   redirectUrlAfterFormSubmission &&
    //   process.env.NODE_ENV !== "development"
    // ) {
    //   if (submitButtonClassName) {
    //     window.location.href =
    //       redirectUrlAfterFormSubmission +
    //       `?form-type=${submitButtonClassName}`;
    //   } else {
    //     window.location.href = redirectUrlAfterFormSubmission;
    //   }
    // }
  };

  return (
    <form className="row" onSubmit={(e) => e.preventDefault()}>
      <div className={`col-lg-${fullWidth ? 12 : 6} my-2`}>
        <input
          id="firstName"
          name="firstName"
          type="text"
          className={` ${cx({
            [formError]:
              get(formik, "touched.firstName", false) &&
              get(formik, "errors.firstName", false),
            "form-control": true,
          })}`}
          placeholder={i18n.t("contactPage.FirstName")}
          {...formik.getFieldProps("firstName")}
        />
      </div>
      <div className={`col-lg-${fullWidth ? 12 : 6} my-2`}>
        <input
          id="lastName"
          name="lastName"
          type="text"
          className={` ${cx({
            [formError]:
              get(formik, "touched.lastName", false) &&
              get(formik, "errors.lastName", false),
            "form-control": true,
          })}`}
          placeholder={i18n.t("contactPage.LastName")}
          {...formik.getFieldProps("lastName")}
        />
      </div>
      <div className={`col-lg-${fullWidth ? 12 : 6} my-2`}>
        <input
          id="email"
          name="email"
          type="email"
          className={` ${cx({
            [formError]:
              get(formik, "touched.email", false) &&
              get(formik, "errors.email", false),
            "form-control": true,
          })}`}
          placeholder={i18n.t("contactPage.Email")}
          {...formik.getFieldProps("email")}
        />
      </div>
      {phoneNumber && (
        <div className={`col-lg-${fullWidth ? 12 : 6} my-2`}>
          <div className="position-relative">
            <div className={phoneNumberStyles}>
              <span></span>
              <CustomSelectSingle
                // isMenuOpen
                phoneNumber
                options={optionsPhoneNumberCountry}
                value={formik.values.phoneNumberCountry}
                onChange={(value) =>
                  formik.setFieldValue("phoneNumberCountry", value.value)
                }
              />
            </div>
            <input
              id="phoneNumber"
              name="phoneNumber"
              type="phoneNumber"
              className="form-control"
              placeholder={i18n.t("contactPage.PhoneNumber")}
              {...formik.getFieldProps("phoneNumber")}
            />
          </div>
        </div>
      )}
      {clientName ? (
        <div className="col-lg-6 my-2 position-relative">
          <input
            id="clientName"
            name="clientName"
            type="text"
            className="form-control"
            placeholder={i18n.t("ClientName")}
            {...formik.getFieldProps("clientName")}
          />
        </div>
      ) : (
        ""
      )}
      {clientJobTitle ? (
        <div className="col-lg-6 my-2 position-relative">
          <input
            id="clientJobTitle"
            name="clientJobTitle"
            type="text"
            className="form-control"
            placeholder={i18n.t("ClientJobTitle")}
            {...formik.getFieldProps("clientJobTitle")}
          />
        </div>
      ) : (
        ""
      )}

      {companyName ? (
        <div className="col-lg-6 my-2 position-relative">
          <input
            id="companyName"
            name="companyName"
            type="text"
            className="form-control"
            placeholder={i18n.t("CompanyName")}
            {...formik.getFieldProps("companyName")}
          />
        </div>
      ) : (
        ""
      )}

      {clientIndustry ? (
        <div className="col-lg-6 my-2 position-relative">
          <div>
            <CustomSelectSingle
              options={industries()}
              value={formik.values.clientIndustry}
              onChange={(value) =>
                formik.setFieldValue("clientIndustry", value.value)
              }
              placeholder={i18n.t("profilePage.Industry")}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {eventDate ? (
        <div className="col-lg-6 my-2 position-relative">
          <div className={contactDatePickerContainer}>
            <DropdownDatePicker
              placeholderText={i18n.t("EventDate")}
              selected={formik.values.eventDate}
              onChange={(date) => {
                formik.setFieldValue("eventDate", date);
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {city ? (
        <div className="col-lg-6 my-2 position-relative">
          <div>
            <CustomSelectMulti
              options={optionsCity}
              value={formik.values.city}
              onChange={(value) => {
                formik.setFieldValue("city", value);
              }}
              placeholder={i18n.t("CityImLocated")}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {businessAddress ? (
        <div className="col-lg-6 my-2 position-relative">
          <input
            id="businessAddress"
            name="businessAddress"
            type="text"
            className="form-control"
            placeholder={i18n.t("BusinessAddress")}
            {...formik.getFieldProps("businessAddress")}
          />
        </div>
      ) : (
        ""
      )}
      {companySize ? (
        <div className="col-lg-6 my-2 position-relative">
          <div>
            <CustomSelectSingle
              options={optionsCompanySize}
              value={formik.values.companySize}
              onChange={(value) =>
                formik.setFieldValue("companySize", value.value)
              }
              placeholder={i18n.t("CompanySize")}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {numberOfGuests ? (
        <div className="col-lg-6 my-2 position-relative">
          <div>
            <CustomSelectSingle
              options={optionsNumberOfGuests}
              value={formik.values.numberOfGuests}
              onChange={(value) =>
                formik.setFieldValue("numberOfGuests", value.value)
              }
              placeholder={i18n.t("ExpectedNumberOfGuests")}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {moveInDate ? (
        <div className="col-lg-6 my-2 position-relative">
          <div className={contactDatePickerContainer}>
            <DropdownDatePicker
              placeholderText={i18n.t("AnticipatedMoveInDate")}
              selected={formik.values.moveInDate}
              onChange={(date) => {
                formik.setFieldValue("moveInDate", date);
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {numberOfPeople ? (
        <div className="col-lg-6 my-2 position-relative">
          <div>
            <CustomSelectSingle
              options={optionsNumberOfPeople}
              value={formik.values.numberOfPeople}
              onChange={(value) =>
                formik.setFieldValue("numberOfPeople", value.value)
              }
              placeholder={i18n.t("NumberOfPeople")}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {locationClientInterest ? (
        <div className="col-lg-6 my-2 position-relative">
          <div>
            <CustomSelectMulti
              options={optionsLocationInterest}
              value={formik.values.locationClientInterest}
              onChange={(value) => {
                formik.setFieldValue("locationClientInterest", value);
              }}
              placeholder={i18n.t("LocationClientIsInterestedIn")}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {productClientInterest ? (
        <div className="col-lg-6 my-2 position-relative">
          <div>
            <CustomSelectMulti
              options={membershipProductTypes()}
              value={formik.values.productClientInterest}
              onChange={(value) => {
                formik.setFieldValue("productClientInterest", value);
              }}
              placeholder={i18n.t("ProductClientIsInterestedIn")}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {productInterest ? (
        <div className="col-lg-6 my-2 position-relative">
          <div>
            <CustomSelectMulti
              options={membershipProductTypes()}
              value={formik.values.productInterest}
              onChange={(value) => {
                formik.setFieldValue("productInterest", value);
              }}
              placeholder={i18n.t("Product you are inquiring about:")}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {locationInterest ? (
        <div className="col-lg-6 my-2 position-relative">
          <div>
            <CustomSelectMulti
              options={iqLocations()}
              value={formik.values.locationInterest}
              onChange={(value) => {
                formik.setFieldValue("locationInterest", value);
              }}
              placeholder={i18n.t("Location you are inquiring about:")}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {isProductInquiry && privateOfficeSelected && (
        <>
          <div className="col-lg-6 my-2 position-relative">
            <div>
              <CustomSelectSingle
                options={areYouLookingForOfficeSpace()}
                value={formik.values.lookingForWork}
                onChange={(value) =>
                  formik.setFieldValue("lookingForWork", value)
                }
                placeholder={i18n.t("Are you looking for office space?")}
              />
            </div>
          </div>
          <div className="col-lg-6 my-2 position-relative">
            <div>
              <CustomSelectSingle
                options={teamSizes()}
                value={formik.values.teamSize}
                onChange={(value) => formik.setFieldValue("teamSize", value)}
                placeholder={i18n.t("Number of desks required.")}
              />
            </div>
          </div>
          <div className="col-lg-12 my-2 position-relative">
            <div>
              <CustomSelectSingle
                options={currentWorkingEnvironment()}
                value={formik.values.currentWorkingEnvironment}
                onChange={(value) =>
                  formik.setFieldValue("currentWorkingEnvironment", value)
                }
                placeholder={i18n.t(
                  "What is your current working environment?",
                )}
              />
            </div>
          </div>
        </>
      )}

      {foundOutIQ ? (
        <div className="col-lg-6 my-2 position-relative">
          <div>
            <input
              id="foundOutIQ"
              name="foundOutIQ"
              type="text"
              className="form-control"
              placeholder={i18n.t("How did you hear about us?")}
              {...formik.getFieldProps("foundOutIQ")}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="col-12 my-2">
        <textarea
          id="additionalInfo"
          name="additionalInfo"
          className="form-control"
          rows={3}
          style={{ resize: "vertical" }}
          placeholder={messagePlaceholder}
          {...formik.getFieldProps("additionalInfo")}
        />
      </div>
      {marketingEmails && (
        <div className="col-12">
          <p
            onClick={(e) => {
              e.preventDefault();
              formik.setFieldValue(
                "marketingEmails",
                !formik.values.marketingEmails,
              );
            }}
            role={"presentation"}
            className={"cursor-pointer d-flex align-items-start pt-3"}
          >
            <img
              alt={""}
              src={formik.values.marketingEmails ? CheckboxChecked : Checkbox}
              className={"me-2"}
            />
            <span style={{ marginTop: "1px" }}>
              {i18n.t("OptInForIqExclusiveEmails")}{" "}
            </span>
          </p>
        </div>
      )}
      {paymentType && (
        <div className="col-12 my-2">
          <div className="row">
            <div className="col-lg-6">
              <div>
                <CustomSelectSingle
                  options={optionsPaymentType[i18n.t("lang") || "en"]}
                  value={formik.values.paymentType}
                  onChange={(value) => {
                    formik.setFieldValue("paymentType", value);
                  }}
                  placeholder={i18n.t("PaymentType")}
                />
              </div>

              <p className="small-p mt-2 mt-lg-3">
                {i18n.t("PaymentTypeFootnote")}
              </p>
            </div>
            <div className="col-lg-6 d-flex mt-2 mt-lg-0">
              <div className="me-3 me-sm-4" style={{ width: 80 }}>
                <div>
                  <input
                    id="transitNumber"
                    name="transitNumber"
                    type="text"
                    className="form-control"
                    style={{ paddingLeft: 16, paddingRight: 14 }}
                    placeholder={"12345"}
                    {...formik.getFieldProps("transitNumber")}
                  />
                </div>
                <p className="small-p mt-2 mt-lg-3">
                  {i18n.t("TransitNumber")}
                </p>
              </div>
              <div className="me-3 me-sm-4" style={{ width: 64 }}>
                <div>
                  <input
                    id="institutionNumber"
                    name="institutionNumber"
                    type="text"
                    className="form-control"
                    style={{ paddingLeft: 16, paddingRight: 14 }}
                    placeholder={"123"}
                    {...formik.getFieldProps("institutionNumber")}
                  />
                </div>
                <p className="small-p mt-2 mt-lg-3">
                  {i18n.t("InstitutionNumber")}
                </p>
              </div>
              <div style={{ width: 96 }}>
                <div>
                  <input
                    id="accountNumber"
                    name="accountNumber"
                    type="text"
                    className="form-control"
                    style={{ paddingLeft: 16, paddingRight: 14 }}
                    placeholder={"1234567"}
                    {...formik.getFieldProps("accountNumber")}
                  />
                </div>
                <p className="small-p mt-2 mt-lg-3">
                  {i18n.t("AccountNumber")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="col-12">
        <div
          className={`d-flex flex-md-row flex-column align-items-md-center mt-5`}
        >
          <SubmitButton
            label={i18n.t("profilePage.GetInTouch")}
            successLabel={"Submitted"}
            progress={progress}
            success={sent}
            onClick={() => formik.handleSubmit()}
            className={submitButtonClassName}
          />
          {sent && (
            <p className={`fst-italic ms-md-5 mb-0 mt-3 mt-md-0`}>
              {i18n.t("ThankYouWeWillBeInTouchSoon")}
            </p>
          )}
        </div>
      </div>
    </form>
  );
};

export default FormContact;
